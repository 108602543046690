

import React, { useContext, } from "react";
import { Context } from '../Store'
import './Contact.css'

function Contact() {
  const [state, setState] = useContext(Context);
  return (
    <div className='contact'  >

      <div className='oGrid contactoGrid' >

        <div className='PorfolioHeader'>
          {state.english? " CONTACT": "KONTAKT"}
      </div>
        <hr className='hrAbout' />
        {state.english ? `I am happy that you want to get to know me more! If you would like to send me an email,
         send it to emma@daisley.se or if you would like to give me a call, then dial 0727411994.
` : `Vad roligt att du vill lära känna mig mer! 
        Vill du skicka iväg ett mail så skickar du 
        det till emma@daisley.se eller så knappar 
        du in 0727411994 så tar vi ett 
        telefonsamtal. `
        }
        <br />
        <br />
        {state.english ? `See ya!` : `Vi hörs! `}
        <br />
        <br />
        <div className='slashE'>
          /E.
       </div>
      </div>

      <div className ="contactInfo">

        <div className =  'contactItem'>
          <div> Mail </div>
          
          <a href = "mailto:emma@daisley.se" className = 'contactInfo2'>  emma@daisley.se </a>
        </div>
        <div className =  'contactItem' >
          <div> {state.english ? `Mobile` : `Telefon `} </div>
          <div className = 'contactInfo2' > 0727411994 </div>
        </div>
        <div className =  'contactItem'>
          <div> Instagram </div>
           <a  className="contactInfo2"  href="https://www.instagram.com/emmadaisley/?hl=sv"> emmadaisley</a> 
        </div>

        <div>

        </div>
      </div>

    </div>
  );
}

export default Contact;
