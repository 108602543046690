import { Link } from "react-router-dom";
import React from "react";

const InteriorGrid = () => {
    const md = useMediaQuery("(min-width: 768px)")
    return (
        <div className="gridContainer" >
            <div className="theGrid">
            <div className="grid-item1" ><Link to={process.env.PUBLIC_URL + "/villa-t"}> VILLA T</Link></div>
            <div className="grid-item2"><Link to={process.env.PUBLIC_URL + "/apartment-b"}>{md ? "APARTMENT B": "APT B"}</Link> </div>
            <div className="grid-item3"><Link to={process.env.PUBLIC_URL + "/villa-A"}> VILLA Å</Link> </div>
            {/* <div className="grid-item4"> <Link to={process.env.PUBLIC_URL + "/apartment-n"}> APARTMENT N</Link></div> */}
            <div className="grid-item4"> <Link to={process.env.PUBLIC_URL + "/office-s"}> OFFICE S</Link></div>
            {/* <div className="grid-item6"> <Link to={process.env.PUBLIC_URL + "/office-x"}> OFFICE X</Link></div> */}


        </div>
        </div>
    )
    
}

export default InteriorGrid;

function useMediaQuery(query) {
    const mediaQuery = React.useMemo(() => window.matchMedia(query), [query]);
    const [match, setMatch] = React.useState(mediaQuery.matches);
  
    React.useEffect(() => {
      const onChange = () => setMatch(mediaQuery.matches);
      mediaQuery.addEventListener("change", onChange);
  
      return () => mediaQuery.removeEventListener("change", onChange);
    }, [mediaQuery]);
  
    return match;
  }