
import React, { useState } from "react";
import Home from './Components/Home.js'
import Contact from './Components/Contact.js'
import Layout from './Layout.js'
import About from './Components/About.js'
import Store from './Store.js'
import Portfolio from './Components/Portfolio.js'
import ScrollToTop from './Components/ScrollToTop.js'
import {
    Route, 
    Switch, BrowserRouter as Router
}
    from "react-router-dom";
import './App.css';
import Interior from "./Components/Inredning/interior.js";
import VillaT from "./Components/Inredning/villaT"
import VillaA from "./Components/Inredning/villaA"
import ApartmentN from "./Components/Inredning/ApartmentN.js"
import ApartmentB from "./Components/Inredning/apartmentB"
import OfficeS from "./Components/Inredning/officeS.js";







function App() {

    const [targeted, setTargeted] = useState(0);
    const toggleTarget = (i) => setTargeted(i);


    return (
        <Store>
            <Router>
                <Layout setTarget={toggleTarget} >
                <ScrollToTop />
                    <Switch>
                        
                        <Route  path={process.env.PUBLIC_URL + "/contact"}>
                            <Contact />
                        </Route>
                        <Route  path={process.env.PUBLIC_URL + "/about"}>
                            <About />
                        </Route>
                        <Route  path={process.env.PUBLIC_URL + "/portfolio"}>
                            <Portfolio />
                        </Route>
                        <Route  path={process.env.PUBLIC_URL + "/interior"}>
                            <Interior />
                        </Route>
                        <Route  path={process.env.PUBLIC_URL + "/villa-t"}>
                            <VillaT />
                        </Route>
                        <Route  path={process.env.PUBLIC_URL + "/villa-a"}>
                            <VillaA />
                        </Route>
                        <Route  path={process.env.PUBLIC_URL + "/apartment-b"}>
                            <ApartmentB />
                        </Route>
                        <Route  path={process.env.PUBLIC_URL + "/apartment-N"}>
                            <ApartmentN />
                        </Route>
                        <Route  path={process.env.PUBLIC_URL + "/office-s"}>
                            <OfficeS />
                        </Route>
                        <Route   path={process.env.PUBLIC_URL + "/"}>
                            <Home targeted={targeted} />
                        </Route>
                    </Switch>
                   
                </Layout>
               
            </Router>
        </Store>


    );
}

export default App;
