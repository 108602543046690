import React, {  useContext } from "react";
import '../Portfolio.css'
import './interior.css'
import InteriorGrid from './interiorGrid'
import { Context } from '../../Store'


function Inredning() {
  const [state] = useContext(Context);

    return ( 
        <div className='outerPortfolio'>
        <div className='portfolioGrid'>
          <div className='portfolio'  >
            <div className='oGrid' >
              <div className='PorfolioHeader'>
              {state.english ? "INTERIOR PROJECTS": "INREDNINGSPROJEKT"}
            </div>
        
              <hr className='hrAbout' />
              {state.english ? `In my portfolio, I share the projects that I'm most proud of. Through them, I aim to showcase my passion for creating spaces that are both beautiful and practical. My portfolio is a way to demonstrate how I work diligently to bring my clients' visions to life with elegance and precision. You can easily scroll down to explore my various interior design projects.` : 
              `I min portfolio delar jag med mig av de projekt som jag är mest stolt över. Genom dem vill jag visa min passion för att skapa utrymmen som är både vackra och praktiska. Min portfolio är ett sätt att visa hur jag engagerat arbetar för att göra mina kunders visioner till verklighet. Du kan scrolla ner för att utforska mina olika inredningsprojekt.`
              }

            </div>
            
          </div>
          <InteriorGrid/>
        </div>
      </div >
    )
}

export default Inredning;
