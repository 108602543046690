import './interior.css'
import PortfolioItem from "../PortfolioItem.js"
import React, { useContext } from "react";
import { Context } from '../../Store'

const OfficeS = () => {
    const [state] = useContext(Context);

    return (
        <div className="interiorObject" >
            <div className="interiorStart" >
                <div className='oGrid' >
                    <div className='PorfolioHeader'>
                        OFFICE S
                    </div>
                    <hr className='hrAbout' />
                    {!state.english ? "Renovering och möblering av ett 350 kvadratmeter stort kontor på Strandvägen i Stockholm. Kunden hade en önskan om att skapa en kontorsmiljö med en biblioteksliknande atmosfär. I vårt arbete ingick det att planera färgsättning, materialval, fullständig möblering samt dekorering med konst och inredningsdetaljer. Vi skapade även ett praktiskt kök som följde kundens önskemål vad gäller stil, samtidigt som det var anpassat för kontorsbruk." :
                        "Renovation and furnishing of a 350 square meter office on Strandvägen in Stockholm. The client wished to create an office environment with a library-like ambiance. Our work included planning color schemes, material choices, complete furnishings, and decoration with art and interior details. Additionally, we designed a practical kitchen that matched the client's preferred style while being suitable for office use."}  </div>
            </div>
            <div className={`portfolioItem2 modulo`} >
                <PortfolioItem year={2021} hideAgent numberOfPics={9} headerText={"Slutresultat"} gata={"Strandvägen"} gataSubTitle={"Stockholm"} foto={"ELISABETH DALY"} />

            </div>

        </div>
    )

}

export default OfficeS;