import './interior.css'
import PortfolioItem from "../PortfolioItem.js"
import React, { useContext, useState } from "react";
import { Context } from '../../Store'



const ApartmentB = () => {
    const [state] = useContext(Context);

    return (
        <div className="interiorObject apartmentB" >
            <div className="interiorStart" >
                <div className='oGrid' >
                    <div className='PorfolioHeader'>
                        APARTMENT B
                    </div>
                    <hr className='hrAbout' />
                    {state.english ? "A complete renovation and furnishing project for a 300 square meter apartment in Östermalm, handling everything from colors and materials to plastering. Our excellent collaboration with suppliers and builders ensured that the project was completed on time and within budget. The result is a beautiful living space that reflects our commitment to quality and aligns with the client's vision." :
                        "Totalrenovering av en 300 kvm stor lägenhet på Östermalm, projektledning för renovering och möblemang. Projektet innefattade färgsättning, materialval, stuckaturer, fullt möblemang och iordningställande. Vårt goda samarbete med leverantörer och byggare gjorde att projektet slutfördes i tid och hölls inom budget. Resultatet är nu ett vackert hem som är helt i linje med kundens önskemål och som inbjuder till att trivas där."
                    }
                </div>
            </div>
            <div className={`portfolioItem2`} >
                <PortfolioItem year={2022} images={"Apartment B - Möbelförslag"} hideAgent numberOfPics={4} headerText={"Möbelförslag"} gata={"Bältgatan"} gataSubTitle={"Stockholm"} information={"Med anledning av att skyddda kundens integritet är slutresultatet privat"} />
            </div>
        </div>
    )

}

export default ApartmentB;