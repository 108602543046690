import React, { useState, useContext } from "react";
import './Portfolio.css'
import { Context } from '../Store'


function PortfolioItem(props) {

    const [no1State, setNo1State] = useState(1);
    const [state, setState] = useContext(Context);
    const numberOfPics = props.numberOfPics

    const toggleNo1State = (i) => {


        if (i > numberOfPics)
            setNo1State(1);
        else if (i < 1)
            setNo1State(numberOfPics);
        else
            setNo1State(i)

    }

    return (

        <div className='portfolioItemGrid'>
            <div className='portfolioItem2a'  >

                <div className='portfolioItem2text'>
                    <div className='portfolioItemNo'>
                        {props.headerText ?? `No. ${props.no}`}
                    </div>
                    <hr className='hrPortfolio' ></hr>
                    <div className='portfolioItem2head'> {props.gata}</div>
                    <div className='portfolioItem2head2' > {!props.gataSubTitle ? (state.english ? "Gothenburg" : "Göteborg") : props.gataSubTitle}</div>
                    <hr className='hrPortfolio' ></hr>
                    <div className="portfolioYear">
                        {state.english ? "YEAR" : "PROJEKTÅR"}: {props.year}
                    </div>
                    {!props.foto ? "" : state.english ? "PHOTO" : "FOTO"}{!props.foto ? "" : `: ${props.foto}`}
                    <br></br>
                    {props.information?.toUpperCase()}
                    {props.hideAgent ? "" : state.english ? "INTERMEDIARY" : "FÖRMEDLARE"}{!props.hideAgent && ": BJURFORS GÖTEBORG CITY"}
                </div>


            </div>


            {numberOfPics > 0 && <div className='portfolioItem2b'  > <img src={process.env.PUBLIC_URL + "/" + (props.images || props.gata) + "/" + no1State + ".jpg"}></img> </div>}
            <div className="portfolioChangePicContainer">
                {numberOfPics > 0 &&  <div className="portfolioChangePic">
                    <div className='portfolioNo1State'>
                        <div className='No1State'>{no1State > 9 ? "" : "0"}{no1State}</div>
                        <div className="noOfPic">/{numberOfPics > 9 ? "" : "0"}{numberOfPics} </div>
                    </div>
                    <div className='buttons'>
                        <div className="buttonsize" onClick={() => toggleNo1State(no1State - 1)}>
                            <span className="buttonPort1" ></span>
                        </div>
                        <div className="buttonsize" onClick={() => toggleNo1State(no1State + 1)}>
                            <span className="buttonPort2" ></span>
                        </div>

                    </div>
                </div>}
               
            </div>
        </div>

    )
}
export default PortfolioItem;