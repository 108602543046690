import React, { useState, useContext } from "react";
import Footer from "./Components/Footer.js";
import './Layout.css'
import { Context } from './Store'
import {
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    Dropdown, DropdownToggle, DropdownMenu
} from 'reactstrap';
import { Link } from "react-router-dom";




const Layout = (props) => {

    const [state, setState] = useContext(Context);
    const [isOpen, setIsOpen] = useState(false);
    const [targeted, setTargeted] = useState([false, false, false, false]);
    const [toggled, setToggle] = useState(false);
    const [openPortfolio, setOpenPortfolio] = useState(false);


    const toggleTarget = (i) => setTargeted(i);

    const handleEnglish = (eng) => {

        const st = { english: eng }
        setState(st)


    }

    const toggle = () => {
        setIsOpen(!isOpen);
        setToggle(!toggled);
    }

    const toggleOff = () => {
        setIsOpen(false);
        setToggle(false);
    }

    const mouseOverHandler = (list, index) => {
        toggleTarget(list);
        props.setTarget(index);
    }

    const mouseLeaveHandler = (list) => {
        toggleTarget(list);
        props.setTarget(0);
    }

    const portfolioMouseOver = () => {
        mouseOverHandler([true, false, true, true], 2)
        setOpenPortfolio(true)
    }

    const portfolioMouseLeave = () => {
        mouseLeaveHandler([false, false, false, false])
        setOpenPortfolio(false)

    }

    let content = <div>{props.children} <Footer></Footer></div>;
    let toggledimage = ""
    let toggledicon = "notToggled"
    if (toggled) {
        toggledimage = "navbartoggled";
        toggledicon = "toggled";
        content = null;
    }
    let navbaren = state.english ?
        //engelska
        <Navbar light expand="md" style={{ width: '100%', paddingTop: '45px' }}  >
            <div className='logo'>
                <NavbarBrand href={process.env.PUBLIC_URL + "/"}  ><div className='navitemBrand'>  EMMA DAISLEY</div></NavbarBrand>
            </div>
            <NavbarToggler onClick={toggle} className={"navbarToggler " + toggledicon} />
            <Collapse isOpen={isOpen} navbar>
                <Nav navbar className="ml-auto" >

                    <NavItem className="navitem">
                        <Link onClick={toggleOff} onMouseOver={() => mouseOverHandler([false, true, true, true], 1)} onMouseLeave={() => mouseLeaveHandler([false, false, false, false])} to={process.env.PUBLIC_URL + "/"} style={{ opacity: targeted[0] ? 0 : 1, transition: '0.5s' }}> HOME  </Link>
                    </NavItem>
                    <Dropdown color="#a19485" nav className="navitem" isOpen={openPortfolio} onMouseOver={portfolioMouseOver} onMouseLeave={portfolioMouseLeave} style={{ opacity: targeted[1] ? 0 : 1, transition: '0.5s' }}>
                        <DropdownToggle nav style={{ color: "white", paddingTop: 0, paddingBottom: 0, marginBottom: "5px" }}  >
                            PORTFOLIO
                        </DropdownToggle>
                        <DropdownMenu className="dropdownNavContent" style={{ border: "none", background: "transparent", paddingLeft: "8px" }} >
                            <Link className="portfolioSubLink" onClick={toggleOff} to={process.env.PUBLIC_URL + "/portfolio"} > STYLING </Link>
                            <br></br>
                            <Link style={{ width: "100%" }} className="portfolioSubLink" onClick={toggleOff} to={process.env.PUBLIC_URL + "/interior"} > INTERIOR-PROJECTS</Link>
                        </DropdownMenu>
                    </Dropdown>
                    <NavItem className="navitem" >
                        <Link onClick={toggleOff} onMouseOver={() => mouseOverHandler([true, true, false, true], 3)} onMouseLeave={() => mouseLeaveHandler([false, false, false, false])} to={process.env.PUBLIC_URL + "/about/"} style={{ opacity: targeted[2] ? 0 : 1, transition: '0.5s' }}> ABOUT ME</Link>
                    </NavItem>

                    <NavItem className="navitem" >
                        <Link onClick={toggleOff} onMouseOver={() => mouseOverHandler([true, true, true, false], 4)} onMouseLeave={() => mouseLeaveHandler([false, false, false, false])} to={process.env.PUBLIC_URL + "/contact"} style={{ opacity: targeted[3] ? 0 : 1, transition: '0.5s' }} > CONTACT ME </Link>
                    </NavItem>
                    <NavItem className="navitem engSvMobile" >
                        <a onClick={() => handleEnglish(!state.english)}> {state.english ? "Svenska " : "English"} </a>
                    </NavItem>
                </Nav>

            </Collapse>
        </Navbar> :
        //svenska
        <Navbar light expand="md" style={{ width: '100%', paddingTop: '45px' }}  >
            <div className='logo'>
                <NavbarBrand href={process.env.PUBLIC_URL + "/"}  ><div className='navitemBrand'>  EMMA DAISLEY</div></NavbarBrand>
            </div>
            <NavbarToggler onClick={toggle} className={"navbarToggler " + toggledicon} />
            <Collapse isOpen={isOpen} navbar>
                <Nav navbar className="ml-auto" >

                    <NavItem className="navitem">
                        <Link onClick={toggleOff} onMouseOver={() => mouseOverHandler([false, true, true, true], 1)} onMouseLeave={() => mouseLeaveHandler([false, false, false, false])} to={process.env.PUBLIC_URL + "/"} style={{ opacity: targeted[0] ? 0 : 1, transition: '0.5s' }}> HEM  </Link>
                    </NavItem>

                    <Dropdown color="#a19485" nav className="navitem" isOpen={openPortfolio} onMouseOver={portfolioMouseOver} onMouseLeave={portfolioMouseLeave} style={{ opacity: targeted[1] ? 0 : 1, transition: '0.5s' }}>
                        <DropdownToggle nav style={{ color: "white", paddingTop: 0, paddingBottom: 0, marginBottom: "5px" }}  >
                            PORTFOLIO
                        </DropdownToggle>
                        <DropdownMenu className="dropdownNavContent" style={{ border: "none", background: "transparent", paddingLeft: "8px" }} >
                            <Link className="portfolioSubLink" onClick={toggleOff} to={process.env.PUBLIC_URL + "/portfolio"} > STYLING</Link>
                            <br></br>
                            <Link style={{ width: "100%" }} className="portfolioSubLink" onClick={toggleOff} to={process.env.PUBLIC_URL + "/interior"} > INREDNINGSPROJEKT</Link>
                        </DropdownMenu>
                    </Dropdown>
                    <NavItem className="navitem" >
                        <Link onClick={toggleOff} onMouseOver={() => mouseOverHandler([true, true, false, true], 3)} onMouseLeave={() => mouseLeaveHandler([false, false, false, false])} to={process.env.PUBLIC_URL + "/about"} style={{ opacity: targeted[2] ? 0 : 1, transition: '0.5s' }}> OM MIG</Link>
                    </NavItem>

                    <NavItem className="navitem" >
                        <Link onClick={toggleOff} onMouseOver={() => mouseOverHandler([true, true, true, false], 4)} onMouseLeave={() => mouseLeaveHandler([false, false, false, false])} to={process.env.PUBLIC_URL + "/contact"} style={{ opacity: targeted[3] ? 0 : 1, transition: '0.5s' }} > KONTAKTA MIG</Link>
                    </NavItem>
                    <NavItem className="navitem engSvMobile" >
                        <a onClick={() => handleEnglish(!state.english)}> {state.english ? "Svenska " : "English"} </a>
                    </NavItem>
                </Nav>

            </Collapse>
        </Navbar>

    return (

        <div className={"layout " + toggledimage}  >
            <div className="engSv">
                <a onClick={() => handleEnglish(!state.english)}> {state.english ? "Svenska " : "English"} </a>
            </div>
            <header className="header">

                {navbaren}

            </header>

            {content}

        </div>




    );



}; export default Layout;
