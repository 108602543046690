import React, {  useContext } from "react";
import PortfolioItem from "./PortfolioItem.js"
import './Portfolio.css'
import { Context } from '../Store'


function Portfolio() {

  const [state] = useContext(Context);
  const items = [
    { gata:"Vidblicksgatan 3", year:"2021",foto:"MARIA SAHLANDER",  numberOfPics: 5},
    { gata:"Egnahemsvägen", year:"2021",foto:"ALEN CORDIC",  numberOfPics: 7},
    {gata:"Karlfeldtsgatan", year:"2021", foto:"ALEN CORDIC",  numberOfPics : 9},
    {gata:"Kastellgatan 7", year : "2020", foto:"JENS ELIASSON",  numberOfPics : 9},
   {gata:"Erik Dahlbergsgatan", year:"2021", foto:"ALEN CORDIC",  numberOfPics : 3},
   {gata:"Kobbarnasväg", year:"2019" ,foto:"ALEN CORDIC",  numberOfPics : 3},
  ]

  const reactItems = items.map((item,i) => 
    <div className= {`portfolioItem2 ${i %2 !== 0 && "modulo"}` } >
    <PortfolioItem no={i+1} gata={item.gata} year={item.year} foto={item.foto}  numberOfPics = {item.numberOfPics} />
  </div>
  )
   
  return (
    <div className='outerPortfolio'>
      <div className='portfolioGrid'>
        <div className='portfolio'  >
          <div className='oGrid' >
            <div className='PorfolioHeader'>
              STYLING
            </div>
            <hr className='hrAbout' />
            {state.english ? `In my portfolio, I share the projects that I'm most proud of. Through them, I aim to showcase my passion for creating spaces that are both beautiful and practical. My portfolio is a way to demonstrate how I work diligently to bring my clients' visions to life with elegance and precision. You can easily scroll down to explore my various stylings.` :
             `I min portfolio delar jag med mig av de projekt som jag är mest stolt över. Genom dem vill jag visa min passion för att skapa utrymmen som är både vackra och praktiska. Min portfolio är ett sätt att visa hur jag engagerat arbetar för att göra mina kunders visioner till verklighet. Du kan scrolla ner för att utforska mina olika stylingar.`}
          </div>
        </div>
        {reactItems}
      </div>
    </div >
  );
}

export default Portfolio;
