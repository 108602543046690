import './interior.css'
import PortfolioItem from "../PortfolioItem.js"
import React, { useContext } from "react";
import { Context } from '../../Store'

const ApartmentN = () => {
    const [state] = useContext(Context);

    return (
        <div className="interiorObject" >
            <div className="interiorStart" >
                <div className='oGrid' >
                    <div className='PorfolioHeader'>
                        APARTMENT N
                    </div>
                    <hr className='hrAbout' />
                    {state.english ? "Complete renovation of a 43 square meter apartment on Kungsholmen, Stockholm. I was responsible for project management of the renovation and furnishing. New electrical systems and lighting solutions were installed, and a timeless, practical kitchen was designed. I carefully selected materials and color schemes, and handpicked furniture to achieve the desired result." :
                        "Totalrenovering av en 43 kvadratmeter stor lägenhet på Kungsholmen i Stockholm. Jag var ansvarig för projektledning av renovering och möblering. Lägenheten fick en ny planlösning för att skapa större sociala ytor och för att förbättra förvaringslösningar. Nya elinstallationer och belysningslösningar sattes på plats, designade ett tidlöst och praktiskt kök. Jag valde noggrant material och färger och handplockade möbler för att skapa det önskade resultatet."}
                </div>
            </div>
            <div className={`portfolioItem2`} >
                <PortfolioItem year={2022} hideAgent numberOfPics={0} headerText={"Möbelförslag"} gata={"Nicandervägen"} gataSubTitle={"Stockholm"} information={"Det finns många olika hem men detta är mitt. "} />
            </div>
            <div className={`portfolioItem2 modulo`} >
                <PortfolioItem year={2022} hideAgent numberOfPics={0} headerText={"Slutresultat"} gata={"Nicandervägen"} gataSubTitle={"Stockholm"} information={"Bilder kommer snart "} />

            </div>

        </div>
    )

}

export default ApartmentN;