import './interior.css'
import PortfolioItem from "../PortfolioItem.js"
import React, { useContext } from "react";
import { Context } from '../../Store'

const VillaA = () => {
    const [state] = useContext(Context);

    return (
        <div className="interiorObject" >
            <div className="interiorStart" >
                <div className='oGrid' >
                    <div className='PorfolioHeader'>
                        VILLA Å
                    </div>
                    <hr className='hrAbout' />
                    {!state.english ? "Möblering av en fjällstuga i Björnen, Åre. Mitt arbete innefattade att noggrant planera val av möbler och inredningsdetaljer. Det resulterade i en inbjudande vinterbostad som återspeglade kundens vision." :
                        "Furnishing a mountain cabin in Björnen, Åre. My work involved carefully planning the selection of furniture and interior details. The result was a welcoming winter residence that reflected the client's vision."}
                </div>
            </div>
            <div className={`portfolioItem2 modulo`} >
                <PortfolioItem year={2022} hideAgent numberOfPics={6} headerText={"Slutresultat"} gata={"Villa Å"} gataSubTitle={"Åre"} foto={"ERIKA SJÖHOLT"} />

            </div>

        </div>
    )

}

export default VillaA;