import React, { useContext, useState } from "react";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContainerWrapper from './ContainerWrapper.js';
import "./About.css"
import { Context } from '../Store'

function About() {

  const [state, setState] = useContext(Context);
  const [hovered, setHovered] = useState(false);
  return (
    <div className='aboutGrid'>
      <div className='about'  >
        <div className='oGridAbout oGrid' >

          <div className='omMigHeader'>
            {state.english ? "ABOUT ME" : "OM MIG"}
          </div>
          <hr className='hrAbout' />
          <div className="omMigText">
            {state.english ? `Since moving to Stockholm six months ago I have been working as an interior stylist and freelanced interior projects. Before I moved I worked as an
             Interior Stylist and Social Media Manager at Bon Interior in Gothenburg.
              Challenges motivate me as I am a curious and competitive person. 
              At the moment I am looking for a challenge, I want to develop my knowledge in
              commercial and private interior design projects.
              ` :
              `Passionerad och resultatinriktad inredningsdesigner med sju års erfarenhet.
               Kombinerar det vackra med det funktionella för att nå kundens vision.
                Stävar alltid efter att skapa bättre och mer inspirerande projekt, intresset för att resa och utforska utvecklar mitt kreativa tänkande. `}
          </div>

        </div>


      </div>
      <ContainerWrapper>
        <Row style={{ width: '100%' }}>
          <Col xl={7} >
            <div className='aboutItem2a'>
              <div className='aboutItem2aText'>
                <div className='aboutItem2aHead'> {state.english ? "WHO IS EMMA" : "VEM ÄR EMMA"}</div>

                {state.english ? '10 QUICK ONES' : '10 SNABBA'}
                <hr className='hrEmma' />
               
                  <div className='tenquick'>
                    <div className='quickOne'> {state.english ? "My name is Emma Daisley and I am 29 years old.": "Jag heter Emma Daisley och är 29 år gammal." }</div>
                    <div className='quickOne'> {state.english ? "My dad was an Englishman, hence my surname. ": "Pappa var engelsman, därav mitt vackra efternamn."}</div>
                    <div className='quickOne'>{state.english ?  "Like to have a laugh.":"Nära till skratt."}</div>
                    <div className='quickOne'>{state.english ?  "I am energised and can have trouble sitting down for to long.":"Har svårt att sitta still."}</div>
                    <div className='quickOne'>{state.english ?  "I have a sweet tooth.":"Säger aldrig nej till sötsaker."}</div>
                    <div className='quickOne'>{state.english ?  "Always curious.":"Nyfiken i en strut."}</div>
                    <div className='quickOne'>{state.english ?  "To travel and explore new places with my boyfriend is the one of the best things I know.": "Att resa och upptäcka nya platser med min sambo är det bästa jag vet." } </div>
                    <div className='quickOne'>{state.english ?  "Love the sun as all other swedes.":"Soldyrkare som alla andra svenskar."}</div>
                    <div className='quickOne'>{state.english ?  "All time favorite song is This Girl with Kungs, it makes me happy every time!":"Min absoluta favoritlåt är This Girl med Kungs, den gör mig glad varje gång!"}</div>
                    <div className='quickOne'>{state.english ?  "Never leave home without making my bed.":"Går aldrig hemifrån utan en bäddad säng."}</div>
                  </div>
              </div>
            </div>
          </Col>
          <Col xl={5} style={{ display: 'flex', justifyContent: "center" }}>
            <img on className="aboutImage" src={process.env.PUBLIC_URL + "/Emma/" + (!hovered ? "m1" : "m2") + ".png"} onMouseOver={() => setHovered(!hovered)}
              onMouseOut={() => setHovered(!hovered)} />
          </Col>
        </Row>
      </ContainerWrapper>

    </div>
  );
}

export default About;
