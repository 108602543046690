import React from "react";
import Container from 'react-bootstrap/Container';
import "./ContainerWrapper.css"

function ContainerWrapper(props) {
    return (
<Container className = "aboutContainer"  >
       {props.children}
      </Container>
        );
    }
    
    export default ContainerWrapper;