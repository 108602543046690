import React, { useContext, useState } from "react";
import './Home.css'
import { Context } from '../Store'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ContainerWrapper from './ContainerWrapper.js';
import { Link } from "react-router-dom";


const Home = (props) => {
  const [state] = useContext(Context);
  const [hovered, setHovered] = useState(false);


  let content = <div className='homeGrid'>
    <div className='home'   >

      <div className='imageHomePort' style={{ opacity: props.targeted == 2 ? 1 : 0, transition: '0.5s' }} >
      </div>
      <div className='imageHomeAbout' style={{ opacity: props.targeted == 3 ? 1 : 0, transition: '0.5s' }} >
      </div>
      <div className='imageHomeContact' style={{ opacity: props.targeted == 4 ? 1 : 0, transition: '0.5s' }} >

      </div>
      <div className='homeFooter' style={{ opacity: (props.targeted == 1 || props.targeted == 0) ? 1 : 0, transition: '0.5s' }}>
        <div  >
          Uppdrag gjort via
    <div className='homeFooterSub'> Bon Interiör.</div>
        </div>
        <div >
          Fotograf
  <div className='homeFooterSub' > Jens Eliasson.</div>

        </div>
        <div >
          Inredningsuppdrag med
     <div className='homeFooterSub'>Bjurfors City Göteborg.</div>
        </div>
      </div>
    </div>

    <ContainerWrapper>
      <Row className = "containerRow" >
        <Col xl={7} >
          <div className='homeItem2a' >
            <div className='homeItem2aText'>
              <span className="Hello">Hej,</span> vad glad jag blir att du tittar in!
      Här har jag i en <Link to={process.env.PUBLIC_URL + "/portfolio"}>portfolio</Link> samlat spännande inredningsprojekt och kreativa stylingar.
      Du kan även läsa några rader <Link to={process.env.PUBLIC_URL + "/about"}>om mig</Link> och vem jag är. Om du vill lära känna mig mer,
      är du varmt välkommen att <Link to={process.env.PUBLIC_URL + "/contact"}>kontakta mig</Link>. Ser fram emot att höra från dig.
    </div>
          </div>
        </Col>
        <Col xl={5} style={{ display: 'flex', justifyContent: "center" }}>
          < img className="homeItem2b" src={process.env.PUBLIC_URL + "/Emma/" + (!hovered ? "f1" : "f2") + ".png"}
            onMouseOver={() => setHovered(!hovered)} onMouseOut={() => setHovered(!hovered)} />
        </Col>
      </Row>
    </ContainerWrapper>

  </div>

  if (state.english) {
    content = <div className='homeGrid'>
      <div className='home'   >

        <div className='imageHomePort' style={{ opacity: props.targeted == 2 ? 1 : 0, transition: '0.5s' }} >
        </div>
        <div className='imageHomeAbout' style={{ opacity: props.targeted == 3 ? 1 : 0, transition: '0.5s' }} >
        </div>
        <div className='imageHomeContact' style={{ opacity: props.targeted == 4 ? 1 : 0, transition: '0.5s' }} >

        </div>
        <div className='homeFooter' style={{ opacity: (props.targeted == 1 || props.targeted == 0) ? 1 : 0, transition: '0.5s' }}>
          <div  >
            Project with
    <div className='homeFooterSub'> Bon Interiör.</div>
          </div>
          <div >
            Photographer
  <div className='homeFooterSub'> Jens Eliasson.</div>
          </div>
          <div >
            Intermediary
     <div className='homeFooterSub'>Bjurfors City Göteborg.</div>
          </div>
        </div>
      </div>
      <ContainerWrapper>
        <Row className = "containerRow" >
          <Col xl={7} >
            <div className='homeItem2a'  >
              <div className='homeItem2aText'>
                < span className='Hello'>  Hello,</span>
                I’m happy to see that you found my website. 
                 Here you'll find my inspiring collection of interior design projects and styled creations in my 
                 <Link to={process.env.PUBLIC_URL + "/portfolio"}> portfolio</Link>.
                  If you want, you can also explore a short introduction <Link to={process.env.PUBLIC_URL + "/about"}>about me</Link>. However, if you want to get to know me better, don't hesitate to
                   <Link to={process.env.PUBLIC_URL + "/contact"}> contact </Link> me directly.
          </div>
            </div>
          </Col>
          <Col xl={5}  style={{ display: 'flex', justifyContent: "center" }}>
          < img className="homeItem2b" src={process.env.PUBLIC_URL + "/Emma/" + (!hovered ? "f1" : "f2") + ".png"}
              onMouseOut={() => setHovered(!hovered)} />
          </Col>
        </Row>
      </ContainerWrapper>
    </div>
  }
  return (
    content
  );
}

export default Home;
