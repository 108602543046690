import PortfolioItem from '../PortfolioItem';
import './interior.css'
import React, { useContext } from "react";
import { Context } from '../../Store'


const VillaT = () => {
    const [state] = useContext(Context);

    return (
        <div className="interiorObject apartmentB" >
            <div className="interiorStart" >
                <div className='oGrid' >
                <div className='PorfolioHeader'>
                    VILLA T
                </div>
                <hr className='hrAbout' />
                {!state.english ? "Projektledning för renovering och möblering av ett sommarhus på vackra Bergholmen, Torö. Mitt arbete innefattade att noggrant planera färgsättning och materialval, designa platsbyggda möbler, fullständig möblering och förbereda huset för sommaren. Resultatet blev en sommarbostad som återspeglade kundens önskemål och vision.":
                "I managed the renovation and furnishing of a lovely summer house on Bergholmen, Torö. This involved carefully choosing colors and materials, designing custom furniture, fully furnishing the house, and getting it ready for the summer. The end result was a summer retreat that perfectly matched the client's wishes."            }
                </div>
            </div>
            <div className= {`portfolioItem2` } >
    <PortfolioItem numberOfPics={5} headerText={"Möbelförslag"} hideAgent year={2023} images={"Villa T - Möbelförslag"} gata={"Bergholmen"} gataSubTitle = {"Torö"} />
  </div>
  <div className= {`portfolioItem2` } style={{background:"#d9d2c0"}} >

    <PortfolioItem numberOfPics={13} headerText={"Slutresultat"} gata={"Bergholmen"} 
            gataTitle={"Bergholmen"} gataSubTitle = {"Torö"} year={2023} hideAgent foto={"KRONFOTO"}/>
    </div>

        </div>
    )
    
}

export default VillaT;