import React from "react";
import './Footer.css'

function Footer(props) {
  return (
    <div className="Footer ">
      <div className="FooterContent">
        <div className ="emma"> EMMA DAISLEY 2023</div>
        <div className="socialMedia">
          <a  className="linkSM"  href="https://www.instagram.com/emmadaisley/?hl=sv"> INSTAGRAM</a>
          <a  className="linkSM" href = "https://www.linkedin.com/in/emma-daisley-25a952106/" >LINKEDIN</a>
          <a  className="linkSM" href =  "https://pl.pinterest.com/daisleydesign/ ">PINTEREST</a>
        </div>

      </div>




    </div>
  )
}

export default Footer;
